<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <rect
      x="220.686"
      y="366.13"
      style="fill:#74CBB4;"
      width="70.639"
      height="81.065"
    />
    <g>
      <polygon
        style="fill:#82DCC7;"
        points="346.161,427.367 165.844,427.367 135.429,467.064 376.576,467.064 	"
      />
      <rect y="44.936" style="fill:#82DCC7;" width="512" height="353.196" />
    </g>
    <rect
      x="40.764"
      y="85.705"
      style="fill:#A0DBFD;"
      width="430.485"
      height="271.681"
    />
    <polygon
      style="fill:#C1E9FD;"
      points="471.248,85.7 471.248,357.381 259.063,357.381 357.057,85.7 "
    />
    <path
      style="fill:#74CBB4;"
      d="M480.383,366.515H31.629V76.566h448.754V366.515z M49.898,348.246h412.216V94.834H49.898V348.246z"
    />
    <g>
      <rect
        x="88.944"
        y="118.625"
        style="fill:#F64B4A;"
        width="18.269"
        height="204.61"
      />
      <rect
        x="134.616"
        y="174.649"
        style="fill:#F64B4A;"
        width="18.269"
        height="148.586"
      />
      <rect
        x="180.288"
        y="219.712"
        style="fill:#F64B4A;"
        width="18.269"
        height="103.523"
      />
      <rect
        x="225.96"
        y="186.828"
        style="fill:#F64B4A;"
        width="18.269"
        height="136.407"
      />
      <rect
        x="271.632"
        y="166.124"
        style="fill:#F64B4A;"
        width="18.269"
        height="157.111"
      />
      <rect
        x="317.304"
        y="229.456"
        style="fill:#F64B4A;"
        width="18.269"
        height="93.78"
      />
      <rect
        x="362.976"
        y="265.993"
        style="fill:#F64B4A;"
        width="18.269"
        height="57.242"
      />
      <rect
        x="408.648"
        y="166.124"
        style="fill:#F64B4A;"
        width="18.269"
        height="157.111"
      />
    </g>
    <g>
      <polygon
        style="fill:#F98585;"
        points="271.632,322.536 289.901,271.883 289.901,323.23 271.632,323.23 	"
      />
      <rect
        x="317.304"
        y="229.456"
        style="fill:#F98585;"
        width="18.269"
        height="93.78"
      />
      <rect
        x="362.976"
        y="265.993"
        style="fill:#F98585;"
        width="18.269"
        height="57.242"
      />
      <rect
        x="408.648"
        y="166.124"
        style="fill:#F98585;"
        width="18.269"
        height="157.111"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
